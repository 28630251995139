'use strict';


angular.module('MyEdriveApp')
	.controller('CarsCtrl', function($scope, $rootScope, $routeParams, $location, $mdToast, ApiService, $animate, $timeout, $parse) {
			$scope.$on('open_help', ($event, args) => {
				if($event.defaultPrevented) return;
				$event.preventDefault();
				$scope.showDialog(args.event, { text: `<img src=js/ng/templates/Hilfstext_Fahrzeugauswahl.jpg style="max-width: 100%" alt="">`, htmlcontent: true });
			});

			$scope.viewstate  = {};
			$scope.quickcheck = {
				km:         0,
				verbrauch:  0,
				treibstoff: 'Benzin'
			};
			$scope.result     = [];

			$timeout(() => {
				// default values
				$rootScope.settings.Jahresfahrleistung              = $rootScope.settings.Jahresfahrleistung || 20000;
				$rootScope.settings.verbrauch_derzeitiges_Fahrzeug  = $rootScope.settings.verbrauch_derzeitiges_Fahrzeug || 7.5;
				$rootScope.settings.treibstoff_derzeitiges_Fahrzeug = $rootScope.settings.treibstoff_derzeitiges_Fahrzeug || 'Benzin';

				$scope.quickcheck = $rootScope.settings;

				$scope.quickcheckSubmit();

				$timeout(() => {
					$scope.$watch('quickcheck', (() => {
						if($scope.viewstate.quickcheck_table_visible) {
							$scope.quickcheckSubmit();
						}
					}).debounce(600), true);
				}, 100);
			}, 100);

			$scope.quickcheckSubmit = () => {
				// show loading spinner after 100ms wait
				var loading_timeout = $timeout(() => {$scope.viewstate.loading = true;}, 100);

				$scope.viewstate.quickcheck_table_visible = true;
				ApiService.callApi({
						method:  'quickcheck',
						params:  {
							Jahresfahrleistung:  $scope.settings.Jahresfahrleistung,
							mittlerer_Verbrauch: $scope.settings.verbrauch_derzeitiges_Fahrzeug,
							Kraftstoff:          $scope.settings.treibstoff_derzeitiges_Fahrzeug
						},
						success: data => {
							$scope.result.length = 0;
							$scope.result.add(data.data.filter(r => r.car._has_calculation_basis));
						},
						cache:   true
					}
				).finally(() => {
					$timeout.cancel(loading_timeout);
					$scope.viewstate.loading = false;
				});
			};

			$scope.selectCar = car => {
				$rootScope.settings.uuid_selected_car = car.uuid;
				$location.url($scope.$user ? '/app' : '/login?url=/app')
			};

			$animate.enabled(false);

			$scope.filter = {
				Segment: null,
				Antrieb: null,
				CO2:     Infinity,
				Preis:   Infinity
			};

			// Reset Max-CO2-Filter to Infinity, when dragging to max-limit
			$scope.getCO2filterMax = () => $scope.result.length ? $scope.result.map('spez. CO2-Emissionen (LCA)').max().ceil().clamp(220, 10000) : 220;
			$scope.getCO2filterMin = () => $scope.result.length ? $scope.result.map('spez. CO2-Emissionen (LCA)').min().floor() : 0;
			$scope.$watch('filter.CO2', (n, o) => {
				if(n && n != Infinity && $scope.result.length && n >= $scope.getCO2filterMax()) {
					$scope.filter.CO2 = Infinity;
				}
			});

			$scope.getPreisfilterMax = () => $scope.result.length ? $scope.result.map('car').map('purchase_price').max().ceil().clamp(5000, 100000) : 100000;
			$scope.getPreisfilterMin = () => $scope.result.length ? $scope.result.map('car').map('purchase_price').min().floor() : 0;
			$scope.$watch('filter.Preis', (n, o) => {
				if(n && n != Infinity && $scope.result.length && n >= $scope.getPreisfilterMax()) {
					$scope.filter.Preis = Infinity;
				}
			});

			$scope.viewstate.result_open      = null;
			$scope.viewstate.table_quickcheck = { order: { by: 'spez. CO2-Emissionen (LCA)' } };

			$scope.viewstate.table_quickcheck.order.set = field => {
				$scope.viewstate.table_quickcheck.order.reverse = ($scope.viewstate.table_quickcheck.order.by === field)
																													? !$scope.viewstate.table_quickcheck.order.reverse
																													: false;
				$scope.viewstate.table_quickcheck.order.by      = field;
			};

			const car_segment_order                                   = {
				'Mini':          10,
				'Kleinwagen':    20,
				'Kompaktklasse': 30,
				'Mittelklasse':  60,
				'Oberklasse':    100
			};
			$scope.viewstate.table_quickcheck.order.car_segment_order = car_segment_order;
			$scope.viewstate.table_quickcheck.order.orderfunc         = r => {
				var getter;
				if($scope.viewstate.table_quickcheck.order.by === 'Segment') {
					getter = r => car_segment_order[r[$scope.viewstate.table_quickcheck.order.by]] || 0;
				}
				else {
					getter = $parse($scope.viewstate.table_quickcheck.order.by);
				}
				return getter(r);
			};


			$scope.filterQuickcheck = r => {
				const f = $scope.filter;
				if(r['Einsparung'] === null) {
					return false;
				}
				if(f.Segment && r.Segment != f.Segment) {
					return false;
				}
				if(f.Antrieb && r.Antrieb != f.Antrieb) {
					return false;
				}
				/**
				 *
				 if(f.Segment && f.Segment.none(r.Segment))
				 {
				 return false;
			 }
				 if(f.Antrieb && f.Antrieb.none(r.Antrieb))
				 {
				 return false;
			 }
				 */
				//noinspection RedundantIfStatementJS
				if(r['spez. CO2-Emissionen (LCA)'] > f.CO2) {
					return false;
				}
				// noinspection RedundantIfStatementJS
				if(r.car.purchase_price > f.Preis) {
					return false;
				}
				return true;
			};
		}
	);

